<template>
  <b-modal
    id="view-transaction-image"
    centered
    title="Transaction Image"
    size="lg"
    hide-footer
  >
    <b-row>
      <b-col class="text-center">
        <b-img-lazy style="max-height:80vh;" :src="appClientUrl + '/uploads/invoice-bank-slip/' + image" fluid alt="Transaction Image"></b-img-lazy>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BRow,
  BCol,
  BImgLazy,

} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";


export default {
  components: {
    BModal,
    BRow,
    BCol,
    BImgLazy,
  },
  props: ["image"],
  data(){
    const { appClientUrl } = $themeConfig.app;
    return{
      appClientUrl,
    };
  }
};
</script>