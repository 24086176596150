<template>
  <b-modal
    id="approve-transaction"
    centered
    size="lg"
    title="Approve Transaction"
    hide-footer
  >
    <div class="d-flex align-items-center justify-content-center" style="height: 19rem;" v-if="intialLoading" >
      <b-spinner style="width: 5rem; height: 5rem; font-size:2rem;" variant="primary" />
    </div>
    <b-row v-else>
      <b-col md="4">
        <div>
          <b-form-group label="Invoice Number:">
              <strong>{{ transaction.invoice_number }}</strong>
          </b-form-group>
        </div>
      </b-col>

      <b-col md="4">
        <b-form-group label="Transaction Status:">
          <strong
            v-if="transaction.status == 'pending'"
            class="text-capitalize text-warning"
          >
            {{ transaction.status }}
          </strong>

          <strong
            v-else-if="transaction.status == 'completed'"
            variant=""
            class="text-capitalize text-success"
          >
            {{ transaction.status }}
          </strong>

          <strong
            v-else-if="transaction.status == 'error'"
            class="text-capitalize text-danger"
          >
            {{ transaction.status }}
          </strong>

          <strong
            v-else-if="transaction.status == 'rejected'"
            class="text-capitalize text-danger"
          >
            {{ transaction.status }}
          </strong>

          <strong
            v-else
            class="text-capitalize text-primary"
          >
            {{ transaction.status }}
          </strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Mode Of Payment:">
          <strong>{{ transaction.mode }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Transaction Amount:">
            <strong>{{ transaction.amount }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Phone:">
          <strong>{{ transaction.phone }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Transaction Code:">
          <strong>{{ transaction.receipt_number }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Transaction Date:">
          <strong>{{ transaction.format_created_at }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Client:">
          <strong>{{ transaction.first_name }} {{ transaction.last_name }}</strong>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Transaction Image:">
          <a
            v-if="transaction.image"
            class="btn-link"
            :href="appClientUrl + '/uploads/invoice-bank-slip/' + transaction.image"
            target="_blank"
          >
            <feather-icon icon="EyeIcon" class="mr-50" />
            <span class="align-middle">View Image</span>
          </a>
        </b-form-group>
      </b-col>

    </b-row>

    <validation-observer ref="approveTransactionFormValidator" #default="{ invalid }">
      <b-form
        class="auth-forgot-password-form mt-2"
         ref="approveTransactionForm"
        @submit.prevent="approveTransaction"
      >
        <b-row>
          <b-col cols="12">
            <!-- last name -->
            <b-form-group
              label-for="amount"
              class="mt-2"
              label="Amount Paid by the Client in Kenya Shillings (KSH)"
            >
              <validation-provider
                #default="{ errors }"
                name="Amount"
                vid="amount"
                rules="required|integer"
              >
                <b-form-input
                  id="amount"
                  v-model.number="amount"
                  type="number"
                  name="amount"
                  placeholder="XXXX"
                />
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

                    
          <b-col cols="12">
            <b-form-group
              label-for="description"
              label="Transaction Description"
            >
              <validation-provider
                #default="{ errors }"
                name="transaction description"
                vid="description"
              >
                <b-form-input
                  id="description"
                  v-model="description"
                  name="description"
                  placeholder="Transaction Description"
                />
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12">
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="hideModal"
              >
                Cancel
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="ml-2"
                type="submit"
                :disabled="isLoading || invalid"
                :class="{ disabled: invalid }"
              >
                <b-spinner small v-if="isLoading" />
                <span v-if="isLoading">&nbsp;</span>
                Approve
              </b-button>
            </div>
          </b-col>
          
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BRow,
  BCol,
  BTable,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
  BImgLazy,

} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";


export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BImgLazy,
    
    ValidationProvider,
    ValidationObserver,
  },
  props: ["id"],
  emits:["transaction-refresh"],
  data(){
    const { appClientUrl } = $themeConfig.app;
    return{
      intialLoading: false,
      isLoading: false,
      transaction: {},
      amount: null,
      description: null,
      appClientUrl,
      required,
      integer,
    };
  },
  methods:{
    approveTransaction(){
      this.isLoading = true;
      this.$refs.approveTransactionFormValidator.validate().then(success => {
        if (success) {
          this.$http
            .post("/invoices/approve-transaction", {
              id: this.id,
              amount: this.amount,
              message: this.description,
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit('transaction-refresh');
              this.isLoading = false;
              this.hideModal();
              this.$nextTick(() => {
                this.$refs.approveTransactionFormValidator.reset();
              });
              this.$refs.approveTransactionForm.reset();
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.isLoading = false;
            });
        }
      });
    },
    hideModal(){
      this.$bvModal.hide('approve-transaction');
    },
  },
  mounted(){
    this.$root.$on('bv::modal::show', (_, modalId) => {
      if(modalId == "approve-transaction"){
        if(this.id && this.id != null && this.id != undefined){
          this.intialLoading = true;
          this.$http
            .get("/invoices/transaction-history/" + this.id + "/show")
            .then((response) => {
              this.transaction = response.data.data;
              this.amount = this.transaction.amount;
              this.intialLoading = false;
            });
        }
      }
    });
  }
};
</script>